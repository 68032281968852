import { FC, useCallback, useEffect, useRef } from 'react';
import { useFormAction } from '../../../../contexts/FormActionContext';
import Checkbox from '../../../shared/form-control/Checkbox';
import ActionBaseProps from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

export type TickboxActionResponse = {
  checked: boolean;
  timestamp: string | null;
};

const TickboxAction: FC<ActionBaseProps<TickboxActionResponse>> = (props) => {
  const { response, required, data } = props;
  const { onAnswer, readOnly, currentAction } = useFormAction(props);
  const firstTime = useRef(true); // used to stop infinite loop

  const onClick = useCallback(
    (newValue: boolean) => {
      if (response == null && !newValue) {
        return;
      }

      onAnswer(newValue ? { checked: newValue, timestamp: new Date().toISOString() } : null);
    },
    [response, onAnswer],
  );

  useEffect(() => {
    if (!response && firstTime.current) {
      onClick(false);
      firstTime.current = false;
    }
  }, [onClick, response]);

  return (
    <div className="flex justify-between" data-cy="tickbox-action">
      <div>
        <ActionTitleDescription required={required} {...data} />
      </div>
      <div className="flex flex-shrink-0 justify-end">
        <Checkbox
          aria-labelledby={`question-title-${currentAction.id}`}
          aria-describedby={`question-description-${currentAction.id}`}
          value={response?.checked ?? false}
          onChange={onClick}
          disabled={readOnly}
        />
      </div>
    </div>
  );
};

export default TickboxAction;
